<template>
  <div :class="`content__share ${modificator}`">
    <div class="content__share-label">{{title}}</div>
    <div class="content__share-list" v-if="this.activeItem.id">
      <div class="content__share-item content__share-item--vk">
        <a href="javascript:void(0)" @click="share('vk')" data-type="vk" ref="vk" :data-title="stipTags(this.activeItem.title)" :data-image="this.activeItem.image_vk">
          <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.7864 25C8.11993 25 0.324797 15.6156 0 0H6.84572C7.07058 11.4615 12.1174 16.3163 16.1149 17.3173V0H22.5609V9.88488C26.5084 9.45946 30.6558 4.95495 32.055 0H38.5009C37.9737 2.56978 36.9228 5.00295 35.4138 7.14732C33.9049 9.29169 31.9704 11.1011 29.7314 12.4625C32.2307 13.7063 34.4382 15.467 36.2082 17.6281C37.9782 19.7893 39.2706 22.3018 40 25H32.9044C32.2497 22.6564 30.9189 20.5586 29.0789 18.9693C27.2389 17.3801 24.9715 16.3701 22.5609 16.0661V25H21.7864Z" fill="white"/></svg>
        </a>
      </div>
      <div class="content__share-item content__share-item--ok">
        <a href="javascript:void(0)" @click="share('ok')" data-type="ok" ref="ok" :data-url="`${url}?result=${this.activeItem.id}`" :data-title="stipTags(this.activeItem.title)" :data-image="this.activeItem.image_ok">
          <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.219 24.6154C24.1394 23.6789 24.2709 22.3411 23.482 21.0034C22.8246 19.9331 21.6412 19.398 20.3264 19.6656C19.8005 19.7993 19.2745 20.0669 18.7486 20.4682C14.6726 23.2776 9.15038 23.1438 5.07441 20.3345C4.81145 20.2007 4.54847 20.0669 4.28551 19.9331C2.8392 19.2642 1.3929 19.5318 0.604005 20.8696C-0.316374 22.2074 -0.184907 23.6789 0.998438 24.883C1.2614 25.1505 1.52437 25.2843 1.65585 25.5519L1.78734 25.6856C3.6281 27.1572 5.86331 28.0937 8.75593 28.495L7.04665 30.2341C5.60034 31.7057 4.02256 33.311 2.57625 34.7826C2.1818 35.184 1.65585 35.8528 1.65585 36.7893C1.65585 37.9933 2.31328 39.1973 3.49662 39.7324C3.89107 40 4.28551 40 4.67996 40C5.33737 40 6.12627 39.7324 6.6522 39.0635C8.62444 37.0569 10.2022 35.4515 11.78 33.8462C11.9115 33.7124 11.9115 33.7124 11.9115 33.7124C11.9115 33.7124 12.043 33.7124 12.043 33.8462C13.0949 35.0502 14.2782 36.1204 15.3301 37.3244C15.856 37.8595 16.3819 38.3947 16.9079 38.9298C17.5653 39.5987 18.0912 39.8662 18.8801 39.8662C20.0634 40 21.3783 39.1973 21.9042 37.9933C22.4301 36.9231 22.1672 35.5853 21.2468 34.7826C19.932 33.4448 18.6171 31.9732 17.1708 30.6355L14.9356 28.3612C16.119 28.2274 17.4338 27.9599 18.6171 27.4248C20.5894 26.7559 22.0357 25.8194 23.219 24.6154Z" fill="white"/>
            <path d="M11.9115 20.8696C17.5653 20.8696 22.1672 16.3211 22.1672 10.5686C22.1672 7.75919 21.1153 5.08361 19.1431 3.07693C17.1708 1.07024 14.6727 0 12.043 0C6.38924 0 1.91882 4.68227 1.78734 10.301C1.78734 13.1104 2.83921 15.6522 4.81145 17.6589C6.65221 19.7993 9.15037 20.8696 11.9115 20.8696ZM9.01889 7.49164C9.80779 6.68897 10.8596 6.28762 11.9115 6.28762C14.2782 6.28762 15.9875 8.16054 15.9875 10.4348C15.9875 12.8428 14.1467 14.5819 11.9115 14.5819C9.54482 14.5819 7.83555 12.709 7.83555 10.4348C7.83555 9.36455 8.22999 8.29432 9.01889 7.49164Z" fill="white"/>
            <path d="M23.219 24.6154C24.1394 23.6789 24.2709 22.3411 23.482 21.0034C22.8246 19.9331 21.6412 19.398 20.3264 19.6656C19.8005 19.7993 19.2745 20.0669 18.7486 20.4682C14.6726 23.2776 9.15038 23.1438 5.07441 20.3345C4.81145 20.2007 4.54847 20.0669 4.28551 19.9331C2.8392 19.2642 1.3929 19.5318 0.604005 20.8696C-0.316374 22.2074 -0.184907 23.6789 0.998438 24.883C1.2614 25.1505 1.52437 25.2843 1.65585 25.5519L1.78734 25.6856C3.6281 27.1572 5.86331 28.0937 8.75593 28.495L7.04665 30.2341C5.60034 31.7057 4.02256 33.311 2.57625 34.7826C2.1818 35.184 1.65585 35.8528 1.65585 36.7893C1.65585 37.9933 2.31328 39.1973 3.49662 39.7324C3.89107 40 4.28551 40 4.67996 40C5.33737 40 6.12627 39.7324 6.6522 39.0635C8.62444 37.0569 10.2022 35.4515 11.78 33.8462C11.9115 33.7124 11.9115 33.7124 11.9115 33.7124C11.9115 33.7124 12.043 33.7124 12.043 33.8462C13.0949 35.0502 14.2782 36.1204 15.3301 37.3244C15.856 37.8595 16.3819 38.3947 16.9079 38.9298C17.5653 39.5987 18.0912 39.8662 18.8801 39.8662C20.0634 40 21.3783 39.1973 21.9042 37.9933C22.4301 36.9231 22.1672 35.5853 21.2468 34.7826C19.932 33.4448 18.6171 31.9732 17.1708 30.6355L14.9356 28.3612C16.119 28.2274 17.4338 27.9599 18.6171 27.4248C20.5894 26.7559 22.0357 25.8194 23.219 24.6154Z" fill="white"/>
          </svg>
        </a>
      </div>
    </div>
    <div class="content__share-list" v-else>
      <div class="content__share-item content__share-item--vk">
        <a href="javascript:void(0)" @click="share('vk')" data-type="vk" ref="vk" :data-image="`${url}/share/main/vk.png`">
          <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.7864 25C8.11993 25 0.324797 15.6156 0 0H6.84572C7.07058 11.4615 12.1174 16.3163 16.1149 17.3173V0H22.5609V9.88488C26.5084 9.45946 30.6558 4.95495 32.055 0H38.5009C37.9737 2.56978 36.9228 5.00295 35.4138 7.14732C33.9049 9.29169 31.9704 11.1011 29.7314 12.4625C32.2307 13.7063 34.4382 15.467 36.2082 17.6281C37.9782 19.7893 39.2706 22.3018 40 25H32.9044C32.2497 22.6564 30.9189 20.5586 29.0789 18.9693C27.2389 17.3801 24.9715 16.3701 22.5609 16.0661V25H21.7864Z" fill="white"/></svg>
        </a>
      </div>
      <div class="content__share-item content__share-item--ok">
        <a href="javascript:void(0)" @click="share('ok')" data-type="ok" ref="ok" :data-image="`${url}/share/main/ok.png`">
          <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.219 24.6154C24.1394 23.6789 24.2709 22.3411 23.482 21.0034C22.8246 19.9331 21.6412 19.398 20.3264 19.6656C19.8005 19.7993 19.2745 20.0669 18.7486 20.4682C14.6726 23.2776 9.15038 23.1438 5.07441 20.3345C4.81145 20.2007 4.54847 20.0669 4.28551 19.9331C2.8392 19.2642 1.3929 19.5318 0.604005 20.8696C-0.316374 22.2074 -0.184907 23.6789 0.998438 24.883C1.2614 25.1505 1.52437 25.2843 1.65585 25.5519L1.78734 25.6856C3.6281 27.1572 5.86331 28.0937 8.75593 28.495L7.04665 30.2341C5.60034 31.7057 4.02256 33.311 2.57625 34.7826C2.1818 35.184 1.65585 35.8528 1.65585 36.7893C1.65585 37.9933 2.31328 39.1973 3.49662 39.7324C3.89107 40 4.28551 40 4.67996 40C5.33737 40 6.12627 39.7324 6.6522 39.0635C8.62444 37.0569 10.2022 35.4515 11.78 33.8462C11.9115 33.7124 11.9115 33.7124 11.9115 33.7124C11.9115 33.7124 12.043 33.7124 12.043 33.8462C13.0949 35.0502 14.2782 36.1204 15.3301 37.3244C15.856 37.8595 16.3819 38.3947 16.9079 38.9298C17.5653 39.5987 18.0912 39.8662 18.8801 39.8662C20.0634 40 21.3783 39.1973 21.9042 37.9933C22.4301 36.9231 22.1672 35.5853 21.2468 34.7826C19.932 33.4448 18.6171 31.9732 17.1708 30.6355L14.9356 28.3612C16.119 28.2274 17.4338 27.9599 18.6171 27.4248C20.5894 26.7559 22.0357 25.8194 23.219 24.6154Z" fill="white"/>
            <path d="M11.9115 20.8696C17.5653 20.8696 22.1672 16.3211 22.1672 10.5686C22.1672 7.75919 21.1153 5.08361 19.1431 3.07693C17.1708 1.07024 14.6727 0 12.043 0C6.38924 0 1.91882 4.68227 1.78734 10.301C1.78734 13.1104 2.83921 15.6522 4.81145 17.6589C6.65221 19.7993 9.15037 20.8696 11.9115 20.8696ZM9.01889 7.49164C9.80779 6.68897 10.8596 6.28762 11.9115 6.28762C14.2782 6.28762 15.9875 8.16054 15.9875 10.4348C15.9875 12.8428 14.1467 14.5819 11.9115 14.5819C9.54482 14.5819 7.83555 12.709 7.83555 10.4348C7.83555 9.36455 8.22999 8.29432 9.01889 7.49164Z" fill="white"/>
            <path d="M23.219 24.6154C24.1394 23.6789 24.2709 22.3411 23.482 21.0034C22.8246 19.9331 21.6412 19.398 20.3264 19.6656C19.8005 19.7993 19.2745 20.0669 18.7486 20.4682C14.6726 23.2776 9.15038 23.1438 5.07441 20.3345C4.81145 20.2007 4.54847 20.0669 4.28551 19.9331C2.8392 19.2642 1.3929 19.5318 0.604005 20.8696C-0.316374 22.2074 -0.184907 23.6789 0.998438 24.883C1.2614 25.1505 1.52437 25.2843 1.65585 25.5519L1.78734 25.6856C3.6281 27.1572 5.86331 28.0937 8.75593 28.495L7.04665 30.2341C5.60034 31.7057 4.02256 33.311 2.57625 34.7826C2.1818 35.184 1.65585 35.8528 1.65585 36.7893C1.65585 37.9933 2.31328 39.1973 3.49662 39.7324C3.89107 40 4.28551 40 4.67996 40C5.33737 40 6.12627 39.7324 6.6522 39.0635C8.62444 37.0569 10.2022 35.4515 11.78 33.8462C11.9115 33.7124 11.9115 33.7124 11.9115 33.7124C11.9115 33.7124 12.043 33.7124 12.043 33.8462C13.0949 35.0502 14.2782 36.1204 15.3301 37.3244C15.856 37.8595 16.3819 38.3947 16.9079 38.9298C17.5653 39.5987 18.0912 39.8662 18.8801 39.8662C20.0634 40 21.3783 39.1973 21.9042 37.9933C22.4301 36.9231 22.1672 35.5853 21.2468 34.7826C19.932 33.4448 18.6171 31.9732 17.1708 30.6355L14.9356 28.3612C16.119 28.2274 17.4338 27.9599 18.6171 27.4248C20.5894 26.7559 22.0357 25.8194 23.219 24.6154Z" fill="white"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import JSShare from "js-share";
  export default {
    name: 'Share',
    data() {
      return {
        url: process.env.VUE_APP_URL,
      }
    },
    methods: {
      share(soc) {
          JSShare.go(this.$refs[soc]);
      },
      stipTags(string) {
        string.replace(/(<([^>]+)>)/gi, "");
        if (string.length > 120) {
          string = string.slice(0, 119) + '...' ;
        }
        return string;
      }
    },
    props: {
      activeItem: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      modificator: {
        type: String,
        required: true,
      }
    }
  }
</script>