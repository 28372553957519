<template>
  <div
    class="corner-share"
    :class="{ 'corner-share--active': isCornerShareActive }"
  >
    <div class="corner-share__content" v-if="isCornerShareActive">
      <div class="corner-share__label">Поделиться:</div>

      <a
        href="javascript:void(0)"
        @click="share('vk')"
        data-type="vk"
        ref="vk"
        :data-image="`${url}/share/main/vk.png`"
        class="corner-share__vk"
      >
        <svg
          width="21"
          height="13"
          viewBox="0 0 21 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1725 12.6818C4.36364 12.6818 0.479995 7.92137 0.318176 0H3.72881C3.84084 5.81409 6.35525 8.27682 8.34686 8.7846V0H11.5583V5.01433C13.5251 4.79853 15.5914 2.51351 16.2884 0H19.4999C19.2372 1.30358 18.7136 2.53786 17.9618 3.62564C17.2101 4.71342 16.2463 5.6313 15.1308 6.32187C16.376 6.95286 17.4758 7.84597 18.3576 8.94226C19.2395 10.0386 19.8833 11.3131 20.2467 12.6818H16.7116C16.3854 11.493 15.7224 10.4288 14.8057 9.62262C13.889 8.81644 12.7593 8.30412 11.5583 8.14988V12.6818H11.1725V12.6818Z"
            fill="white"
          />
        </svg>
      </a>
      <a
        href="javascript:void(0)"
        @click="share('ok')"
        data-type="ok"
        ref="ok"
        :data-image="`${url}/share/main/ok.png`"
        class="corner-share__ok"
      >
        <svg
          width="14"
          height="22"
          viewBox="0 0 14 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6328 13.007C13.1191 12.5122 13.1886 11.8053 12.7717 11.0984C12.4244 10.5328 11.7991 10.2501 11.1043 10.3915C10.8264 10.4622 10.5485 10.6035 10.2706 10.8156C8.11681 12.3001 5.19879 12.2294 3.04501 10.7449C2.90606 10.6742 2.7671 10.6035 2.62815 10.5328C1.8639 10.1794 1.09967 10.3208 0.682809 11.0277C0.196473 11.7346 0.265941 12.5122 0.891231 13.1484C1.03018 13.2898 1.16914 13.3605 1.23861 13.5018L1.3081 13.5725C2.28077 14.3501 3.46187 14.8449 4.99036 15.057L4.08716 15.976C3.32292 16.7536 2.4892 17.6019 1.72496 18.3795C1.51653 18.5915 1.23861 18.945 1.23861 19.4398C1.23861 20.076 1.586 20.7122 2.21129 20.995C2.41972 21.1364 2.62815 21.1364 2.83658 21.1364C3.18396 21.1364 3.60082 20.995 3.87873 20.6415C4.92088 19.5812 5.75461 18.7329 6.58833 17.8846C6.6578 17.8139 6.6578 17.8139 6.6578 17.8139C6.6578 17.8139 6.72728 17.8139 6.72728 17.8846C7.28309 18.5208 7.90838 19.0863 8.4642 19.7226C8.7421 20.0053 9.02001 20.2881 9.29791 20.5708C9.6453 20.9243 9.9232 21.0657 10.3401 21.0657C10.9654 21.1364 11.6601 20.7122 11.938 20.076C12.2159 19.5105 12.077 18.8036 11.5906 18.3795C10.8959 17.6725 10.2011 16.895 9.43687 16.1881L8.25576 14.9863C8.88105 14.9156 9.57582 14.7743 10.2011 14.4915C11.2433 14.138 12.0075 13.6432 12.6328 13.007Z"
            fill="white"
          />
          <path
            d="M6.6578 11.0277C9.64529 11.0277 12.077 8.6242 12.077 5.58452C12.077 4.10003 11.5212 2.68623 10.479 1.62588C9.43686 0.565523 8.11681 0 6.72728 0C3.73978 0 1.37757 2.47415 1.30809 5.44314C1.30809 6.92763 1.86391 8.27075 2.90606 9.3311C3.87873 10.4621 5.19879 11.0277 6.6578 11.0277ZM5.12931 3.95865C5.54617 3.53451 6.10198 3.32244 6.6578 3.32244C7.90838 3.32244 8.81158 4.3121 8.81158 5.51383C8.81158 6.78626 7.8389 7.70522 6.6578 7.70522C5.40722 7.70522 4.50403 6.71557 4.50403 5.51383C4.50403 4.94831 4.71245 4.38279 5.12931 3.95865Z"
            fill="white"
          />
          <path
            d="M12.6328 13.007C13.1191 12.5122 13.1886 11.8053 12.7717 11.0984C12.4244 10.5328 11.7991 10.2501 11.1043 10.3915C10.8264 10.4622 10.5485 10.6035 10.2706 10.8156C8.11681 12.3001 5.19879 12.2294 3.04501 10.7449C2.90606 10.6742 2.7671 10.6035 2.62815 10.5328C1.8639 10.1794 1.09967 10.3208 0.682809 11.0277C0.196473 11.7346 0.265941 12.5122 0.891231 13.1484C1.03018 13.2898 1.16914 13.3605 1.23861 13.5018L1.3081 13.5725C2.28077 14.3501 3.46187 14.8449 4.99036 15.057L4.08716 15.976C3.32292 16.7536 2.4892 17.6019 1.72496 18.3795C1.51653 18.5915 1.23861 18.945 1.23861 19.4398C1.23861 20.076 1.586 20.7122 2.21129 20.995C2.41972 21.1364 2.62815 21.1364 2.83658 21.1364C3.18396 21.1364 3.60082 20.995 3.87873 20.6415C4.92088 19.5812 5.75461 18.7329 6.58833 17.8846C6.6578 17.8139 6.6578 17.8139 6.6578 17.8139C6.6578 17.8139 6.72728 17.8139 6.72728 17.8846C7.28309 18.5208 7.90838 19.0863 8.4642 19.7226C8.7421 20.0053 9.02001 20.2881 9.29791 20.5708C9.6453 20.9243 9.9232 21.0657 10.3401 21.0657C10.9654 21.1364 11.6601 20.7122 11.938 20.076C12.2159 19.5105 12.077 18.8036 11.5906 18.3795C10.8959 17.6725 10.2011 16.895 9.43687 16.1881L8.25576 14.9863C8.88105 14.9156 9.57582 14.7743 10.2011 14.4915C11.2433 14.138 12.0075 13.6432 12.6328 13.007Z"
            fill="white"
          />
        </svg>
      </a>
    </div>

    <div class="corner-share__heart">
      <svg
        width="25"
        height="21"
        viewBox="0 0 25 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="isCornerShareActive = !isCornerShareActive"
      >
        <path
          d="M2.9786 11.8421L12.1579 20.3462C12.4927 20.6563 12.6601 20.8114 12.8636 20.8114C13.0672 20.8114 13.2346 20.6563 13.5694 20.3462L22.7487 11.8421C25.3555 9.4271 25.671 5.41516 23.4738 2.6223L23.1155 2.16679C20.5112 -1.14355 15.3398 -0.583214 13.505 3.20811C13.2455 3.74427 12.4818 3.74427 12.2223 3.20811C10.3875 -0.583214 5.21609 -1.14355 2.6118 2.16679L2.25345 2.6223C0.0562655 5.41516 0.371811 9.4271 2.9786 11.8421Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import JSShare from "js-share";
export default {
  name: "Share",
  data() {
    return {
      isCornerShareActive: false,
      url: process.env.VUE_APP_URL,
    };
  },
  methods: {
    share(soc) {
      if (soc == 'vk') {
        JSShare.go(this.$refs[soc]);
      }
    },
    stipTags(string) {
      string.replace(/(<([^>]+)>)/gi, "");
      if (string.length > 120) {
        string = string.slice(0, 119) + "...";
      }
      return string;
    },
  },
};
</script>

<style lang="sass">
.corner-share
    position: fixed
    top: 17px
    right: 17px
    display: flex
    gap: 20px
    align-items: center
    z-index: 11111

    &__heart
        cursor: pointer

    &__content
        display: flex
        align-items: center

    &__label
        margin-right: 14px
        font-size: 11px
        font-weight: 400
        line-height: 13px
        font-family: Raleway
        letter-spacing: 0em
        text-align: left

    &__vk 
        margin-right: 18px   
</style>
