<template>
  <div class="scene-box" :class="{ active: isBallActive }">
    <Snow />
    <CornerShare v-if="!shakeBall"/>
    <div class="main">
      <div class="main__left">
        <a
          href="/"
          class="logo"
          :class="{ active: isBallActive }"
          v-if="!shakeBall"
        ></a>
        <transition name="fade-text">
          <div v-if="!shakeBall && !isBallActive">
            <div class="main__title">
              Шар предсказаний <br />на <span>2024</span>
            </div>
            <div class="main__text">
              Говорят, что грядущий 2024 год Зеленого Дракона многим откроет
              возможности для самореализации и успеха.
              <br />
              <br />
              Узнайте, что новый год принесет Вам – воспользуйтесь шаром
              предсказаний <br />
              BE DZEN
            </div>
            <div class="main__button">
              <a href="javascript:void(0)" @click="startActive"
                >Получить предсказание</a
              >
            </div>
          </div>
        </transition>
      </div>
      <div
        class="ball-wrapper"
        :class="{
          active: isBallActive && !shakeBall,
          'wobble-hor-top': shakeBall,
        }"
      >
        <div class="ball" :class="{ anim: ballStandartAnim }">
          <div class="ball__top"></div>
          <div class="ball__round">
            <the-dragon v-if="!isBallActive" :is-animating="shakeBall" />
            <div class="ball__round-snowbg"></div>
            <div class="ball__round-shadow"></div>
            <div class="ball__round-bg"></div>
            <div class="ball__round-glass"></div>
            <div class="ball__round-light"></div>
            <div class="ball__round-snow"></div>
          </div>
        </div>
      </div>
      <transition name="bounce">
        <div :class="`content content--${activeItem.id}`" v-if="isShowAdvice">
          <div class="content__box">
            <div class="content__head">
              <p
                v-html="activeItem.title"
                :class="{ small: activeItem.small }"
              ></p>
              <p
                :class="{ small: activeItem.small }"
                v-html="activeItem.text"
                v-if="activeItem.text.length > 1"
              ></p>
            </div>
            <div class="content__info">
              <div
                class="content__logo"
                :class="{ 'content__logo--row': activeItem.logo_2 }"
              >
                <img
                  :src="require(`../assets/img/logos/${activeItem.logo}.png`)"
                  alt=""
                /><img
                  v-if="activeItem.logo_2"
                  :src="require(`../assets/img/logos/${activeItem.logo_2}.png`)"
                  alt=""
                />
              </div>
              <p v-html="activeItem.description"></p>
            </div>
          </div>
          <Share
            title="Поделиться в социальных сетях:"
            modificator="result-share"
            :activeItem="activeItem"
          />
          <div class="content__button">
            <a href="javascript:void(0)" @click="replay"
              ><img src="@/assets/img/reload.svg" alt="" />Еще одно
              предсказание</a
            >
          </div>
          <div class="content__small" v-html="activeItem.note"></div>
        </div>
      </transition>
      <transition name="up">
        <div class="notice" v-if="isShowAdvice">
          <div
            class="notice__text"
            :class="{
              mini: activeItem.info.length > 50,
              micro: activeItem.info.length > 90,
              nano: activeItem.info.length > 130,
            }"
            v-html="activeItem.info"
          ></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Snow from "@/components/Snow";
import Share from "@/components/Share";
import TheDragon from "@/components/Dragon.vue";
import CornerShare from "../components/CornerShare.vue";

export default {
  name: "Main",

  data() {
    return {
      isBallActive: false,
      shakeBall: false,
      isShowAdvice: false,
      ballStandartAnim: true,
      used: [],
      items: [
        {
          id: 1,
          title:
            "В 2024 году Ваша энергия  и оптимистичный настрой будут восхищать окружающих.<br />Все будут пытаться разгадать Ваш секрет красоты и гармонии.",
          text: "Фамвиталь<span>®</span> СМАРТ — Ваше секретное оружие.",
          description: `Фамвиталь<span>®</span> СМАРТ «умные*» капсулы из Франции для вашей красоты. Комплекс антиоксидантов, витаминов и минералов способствует укреплению волос, ногтей, сиянию кожи и коррекции веса**. 
          <a class='custom-link' target='_blank' href="https://famvital.ru/o-tebe/test/">Пройдите тест</a> на нехватку микронутриентов, чтобы войти в новый год без дефицитов.`,
          logo: "01",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_1.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_1.png`,
          note: `
            <small>
              *Созданы с учетом суточных биоритмов организма и оптимальной сочетаемости компонентов
            </small>
            <br />
            <small>
              ** Jacquet A, Coolen V, Vandermander J. Effect of dietary supplementation with INVERSION Femme on slimming, hair loss, and skin and nail parameters in women. Adv Ther. 2007 Sep-Oct;24(5):1154-71. 
            </small>
          `,
          info: "БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ",
        },
        {
          id: 2,
          title: "2024 год пройдет под знаком «расхламления» и «обновления».",
          text: "Вы почувствуете свободу от лишнего и радость приобретения самого важного только в нужном и достаточном количестве.",
          description:
            "Витажиналь<span>®</span> Мама — комплекс 5 базовых микронутриентов с доказанной значимостью для здоровья мамы и малыша. Одна маленькая удобная капсула с прегравидарной подготовки <br />до окончания лактации. <br/><a class='custom-link' target='_blank' href='https://vitagynal.ru/'>Подробнее</a>",
          logo: "11",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_2.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_2.png`,
          note: "",
          info: "БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ",
        },
        {
          id: 3,
          title:
            "В 2024 году Вы вздохнете полной, здоровой грудью! <br />Все болезненные ситуации уйдут благодаря цепочке правильно принятых последовательных рациональных решений.",
          text: "",
          description:
            "Прожестожель<span>®</span> — единственный* в России 1% трансдермальный гель микронизированного прогестерона, у которого в инструкции чётко и без оговорок прописано показание «диффузная фиброзно-кистозная мастопатия» (синоним термина «ДДМЖ**»). <br/> <a class='custom-link' target='_blank' href='https://mammacare.ru'>Посмотрите видео</a> о самодиагностики груди и <a class='custom-link' target='_blank' href='https://mammacare.ru'>пройдите тест</a> на риск рака, чтобы войти в новый год с заботой о здоровье молочной железы.",
          logo: "03",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_3.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_3.png`,
          note: ' <small>*По данным государственного реестра лекарственных средств от декабря 2022 г. <a href="https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=1c9723cd-3711-4292-b020-f6fd92d22ec2&t=" target="_blank">https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=1c9723cd-3711-4292-b020-f6fd92d22ec2&t=</a></small><br/> <small>** ДДМЖ - доброкачественная дисплазия молочной железы</small>',
          info: "ИНФОРМАЦИЯ ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ МЕДИЦИНСКИХ И ФАРМАЦЕВТИЧЕСКИХ РАБОТНИКОВ.",
        },
        {
          id: 4,
          title:
            "В 2024 году Вас ждет много солнца, <br />даже если Вы не собираетесь путешествовать <br />в теплые страны.",
          text: "",
          description:
            "Новинка D3 Форте от Безен — доставка солнца из Бразилии. <br />2000 МЕ витамина D3 в каждой маленькой мягкой желатиновой капсуле, <br />в выгодной упаковке. <br /> <a href='https://d3forte.ru/' target='_blank' class='custom-link'>Зайдите на сайт D3 Форте от Безен</a> для доставки солнца из Бразилии в новом году.",
          logo: "04",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_4.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_4.png`,
          note: "",
          info: "БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ",
        },
        {
          id: 5,
          title:
            "2024 для Вас станет БОЛЬШЕ, чем просто следующим годом. Он поможет осуществить самую заветную мечту!",
          text: "Утрожестан<span>®</span> — больше, чем просто гестаген! ",
          description:
            "Утрожестан<span>®</span> — это прогестерон, такой же как в организме женщины. Естественно и физиологично восполняет уровень прогестерона при состояниях, сопровождающихся его дефицитом. <br /> А также прогестерон — это гормон умиротворения и спокойствия, которые так важны в наше изменчивое время!",
          logo: "05",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_5.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_5.png`,
          note: "",
          info: "ИНФОРМАЦИЯ ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ МЕДИЦИНСКИХ И ФАРМАЦЕВТИЧЕСКИХ РАБОТНИКОВ.",
        },
        {
          id: 6,
          title: "В 2024 году Вы будете играть первую скрипку!",
          text: "Овации зала гарантированы.",
          description:
            "Витажиналь<span>®</span> Инозит — симфония репродуктивного здоровья. 5 элементов для женского и мужского здоровья: подготовка к беременности, комплексная поддержка обмена веществ, гормонального фона <br />и коррекция эстетических проблем* <br /> <a class='custom-link' target='_blank' href='https://inozit.ru/'>Подробнее</a>",
          logo: "02",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_6.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_6.png`,
          note: "* У пациенток с синдромом поликистозных яичников и гиперандрогенией",
          info: "БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ",
        },
        {
          id: 7,
          title:
            "«Мудрому слову — тройная цена» говорит нам русская пословица <br />и демонстрирует, что в 2024 году вас ждет утроение благосостояния и свобода от дефицитов.",
          text: "",
          description:
            "Триожиналь<span>®</span> — единственный* в России тройной удар по атрофии. Дефициты восполняет, от рецидивов защищает!",
          logo: "07",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_7.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_7.png`,
          note: '<small>*Единственный зарегистрированный в России препарат, содержащий эстриол, прогестерон и лактобактерии LCR* — по данным государственного реестра лекарственных средств от декабря 2023 г.: <a href="https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=3eea7f39-e5c1-43ff-94a6-6a4b076a1cfd" target="_blank">https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=3eea7f39-e5c1-43ff-94a6-6a4b076a1cfd</a></small>',
          info: "ИНФОРМАЦИЯ ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ МЕДИЦИНСКИХ И ФАРМАЦЕВТИЧЕСКИХ РАБОТНИКОВ.",
        },
        {
          id: 8,
          title:
            "В 2024 году рядом с Вами всегда будет поддержка и вторая линия комплексной терапии интимного дискомфорта*.",
          text: "Все, что болело в 2023, нормализуется и будет сбалансировано.",
          description:
            "Интравагинальный пробиотик Лактожиналь® и пероральный пробиотик Дуожиналь® — индивидуальный поход к восстановлению микрофлоры и защите от рецидивов вагинальных инфекций. <br/> <a class='custom-link' target='_blank' href='https://duolact.ru/'>Подробнее</a>",
          logo: "08",
          logo_2: "08_1",
          small: true,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_8.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_8.png`,
          note: "* интимный дискомфорт, связанный с обострением бактериального вагиноза и вульвовагинального кандидоза",
          info: "Дуожиналь® БАД. НЕ ЯВЛЯЕТСЯ ЛЕКАРСТВЕННЫМ СРЕДСТВОМ. ИНФОРМАЦИЯ ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ МЕДИЦИНСКИХ И ФАРМАЦЕВТИЧЕСКИХ РАБОТНИКОВ.",
        },
        {
          id: 9,
          title:
            "2024 год пройдет с помпой, без перебоев <br />с поставками удачи, здоровья, счастья <br />и веселья!",
          text: "",
          description:
            "Эстрожель<span>®</span> — обеспечивает оптимальную безопасность МГТ и индивидуальный подход к каждой пациентке. <br /> <a href='https://menoguide.ru/' target='_blank' class='custom-link'>Посетите сайт</a> для оценки степени выраженности климактерического синдрома и знаний техник облегчения состояния в период менопаузы в новом году",
          logo: "09",
          small: false,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_9.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_9.png`,
          note: "",
          info: "ИНФОРМАЦИЯ ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ МЕДИЦИНСКИХ И ФАРМАЦЕВТИЧЕСКИХ РАБОТНИКОВ.",
        },
        {
          id: 10,
          title:
            "Драйв и энергия внутри Вас, почувствуйте! В 2024 году Вам покорится любая высота!",
          text: "Прилив сил и хорошее настроение поднимут Вас на новый уровень!",
          description:
            "Андрогель<span>®</span> — №1 в мире среди трансдермальных тестостеронов*. <br /> Поднимает всё! <br /> О тестостероне, последствиях его дефицита и способах терапии Вы можете узнать <a class='custom-link' target='_blank' href='https://otestosterone.ru/'>на сайте</a>.",
          logo: "10",
          small: false,
          image_vk: `${process.env.VUE_APP_URL}/share/new_vk_10.png`,
          image_ok: `${process.env.VUE_APP_URL}/share/ok/Odnoklassniki_10.png`,
          note: "*Source: Based on internal analysis by Besins Healthcare Monaco S.A.M. using data from the following source: IQVIA MIDAS® for the time period MAT Q2 2023; Geography: 61 countries; Measures: Units, Counting Units and LEU MNF (i.e. Euro fixed quarter exchange rate); Molecule List: TESTOSTERONE; Transdermal: NFC123 IVP, JGP, JSA, JTA, JVA, JVN, JVP, JWN, MSA, MTA, MVA and VED, reflecting estimates of real-world activity. Copyright IQVIA. All rights reserved",
          info: "ИНФОРМАЦИЯ ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ МЕДИЦИНСКИХ И ФАРМАЦЕВТИЧЕСКИХ РАБОТНИКОВ.",
        },
      ],
      activeItem: {},
    };
  },

  methods: {
    setParam(id) {
      this.$router.push({ query: { result: id } });
    },
    startActive() {
      this.ballStandartAnim = false;
      this.activeItem =
        this.items[Math.floor(Math.random() * this.items.length)];
      this.used.push(this.activeItem.id);
      this.shakeBall = true;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      setTimeout(() => {
        this.isBallActive = true;
        this.shakeBall = false;
      }, 3500);
      setTimeout(() => {
        this.isShowAdvice = true;
      }, 4000);
    },
    replay() {
      if (this.used.length == 10) {
        this.used = [];
      }
      this.activeItem = this.items.filter(
        (item) => !this.used.includes(item.id)
      )[
        Math.floor(
          Math.random() *
            this.items.filter((item) => !this.used.includes(item.id)).length
        )
      ];
      this.used.push(this.activeItem.id);
      this.shakeBall = true;
      this.isBallActive = false;
      this.isShowAdvice = false;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      setTimeout(() => {
        this.isBallActive = true;
        this.shakeBall = false;
      }, 3500);
      setTimeout(() => {
        this.isShowAdvice = true;
      }, 4000);
    },
  },

  mounted() {
    this.$router.replace({ query: null });
  },

  components: {
    Snow,
    Share,
    TheDragon,
    CornerShare
},
};
</script>

<style lang="sass">
@function vw($target)
  $vw-context: (1440*.01) * 1px
  @return (calc($target/$vw-context)) * 1vw
@function vwm($target)
  $vw-context: (320*.01) * 1px
  @return (calc($target/$vw-context)) * 1vw

.notice
  width: 100%
  position: fixed
  bottom: 0
  left: 0
  z-index: 999
  &:before
    content: ''
    display: block
    width: 100%
    height: 100%
    position: absolute
    background: rgba(255, 255, 255, 0.2)
    backdrop-filter: blur(25px)
  &__text
    font-size: vw(39px)
    font-weight: 100
    color: #045581
    position: relative
    z-index: 100
    text-align: center
    letter-spacing: vw(10px)
    line-height: vw(52px)
    &.mini
      font-size: 1.908333vw
      letter-spacing: 0
    &.micro
      font-size: 1.308333vw
      letter-spacing: 0

    &.nano
      font-size: 1.2vw
      letter-spacing: 0
.content
  width: vw(969px)
  height: vw(600px)
  position: fixed
  top: 0
  bottom: vw(9px)
  left: vw(-4px)
  right: 0
  z-index: 999999
  margin: auto
  overflow: hidden
  background-color: #006EAB
  background-image: url(../assets/img/bg.svg)
  background-size: contain
  border-radius: vw(10px)
  &--4
    .content__logo img
        transform: scale(1.25) !important
  &__small
    font-size: 0.8vw
    text-align: center
    padding-top: 0.7vw
    padding-left: 5vw
    padding-right: 5vw
  &__button
    text-align: center
    margin-top: vw(17px)
    a
      background-color: #183568
      color: #fff
      height: vw(50px)
      display: inline-flex
      line-height: vw(50px)
      align-items: center
      padding-left: vw(24px)
      padding-right: vw(24px)
      border-radius: vw(40px)
      transition: all 300ms
      font-weight: 500
      letter-spacing: vw(0.3px)
      &:hover
        background-color: #02334D
        img
          transform: rotate(180deg)
      img
        width: vw(14px)
        height: vw(18px)
        transition: all 300ms
        transform-origin: center center
        margin-right: vw(14px)
  &__box
    width: vw(848px)
    height: vw(359px)
    margin: auto
    margin-top: vw(39px)
  &__logo
    width: vw(139px)
    margin: auto
    margin-top: vw(20px)
    height: vw(46px)
    margin-bottom: 0
    &--row
      display: flex
      justify-content: center
      width: 100%
      align-items: center
      img
        width: vw(140px) !important
        height: auto !important
        margin-right: vw(20px)
        &:last-child
          margin-right: 0
    img
      width: 100%
      object-fit: contain
      height: 100%
  &__share
    display: flex
    align-items: center
    justify-content: center
    margin-top: vw(34px)
    &-list
      display: flex
      align-items: center
      margin-left: vw(21px)
    &-label
      font-size: vw(16px)
      color: #EDFAFF
    &-item
      svg
        width: 100% !important
        height: auto !important
        path
          transition: all 300ms
        &:hover
          path
            fill: #B4CCD9 !important
      &--vk
        width: vw(27px)
        margin-right: vw(30px)
      &--ok
        width: vw(17px)
  &__info
    width: 100%
    height: vw(159px)
    background-color: #fff
    background-size: vw(730px)
    background-repeat: no-repeat
    background-position: 50% 50%
    border-bottom-left-radius: vw(10px)
    border-bottom-right-radius: vw(10px)
    display: flex
    flex-direction: column
    p
      text-align: center
      font-size: vw(14px)
      color: #045581
      line-height: vw(18.78px)
      padding-left: vw(62px)
      padding-right: vw(62px)
      margin-top: vw(4px)
      small
        font-size: vw(9px)
        line-height: 1 !important
        display: block
        margin-top: 0.4vw
        margin-bottom: 1vw
        @media screen and (max-width: 1000px)
          font-size: vwm(10px)
          margin-top: 2vw
      span
        font-size: vw(9px)
        display: inline-block
        transform: translateY(vw(-6px))
      &.small
        font-size: vw(20px)
        line-height: vw(25px)
  &__head
    background-image: url(../assets/img/snowflake.svg)
    width: 100%
    height: vw(210px)
    background-color: #183568
    background-size: vw(730px)
    background-repeat: no-repeat
    background-position: 50% 50%
    border-top-left-radius: vw(10px)
    border-top-right-radius: vw(10px)
    text-align: center
    font-size: vw(26px)
    line-height: vw(30.7px)
    color: #fff
    font-weight: 600
    padding-left: vw(137px)
    padding-right: vw(137px)
    display: flex
    flex-direction: column
    justify-content: center
    p
      margin-bottom: vw(14px)
      &:last-child
        margin-bottom: 0
      &.small
        font-size: vw(20px)
        line-height: vw(25px)
        width: calc(100% + vw(100px))
        margin-left: vw(-50px)
      span
        font-size: vw(9px)
        display: inline-block
        transform: translateY(vw(-6px))
.ball
  -webkit-transform-origin: 100% 100%
  transform-origin: 100% 100%
  &.anim
    transform-origin: bottom center
    animation: ball
    animation-duration: 5s
    animation-timing-function: linear
    animation-iteration-count: infinite
    -webkit-backface-visibility: hidden
  &-wrapper
    z-index: 999
    position: absolute
    top: 0
    transition: all 500ms
    -webkit-backface-visibility: hidden
    left: vw(816px)
    &.active
      transform: scale(3.5) translateX(vw(-223px)) translateY(vw(-251px))
      .ball
        animation: none
        &__round-bg
          opacity: 1
  &__top
    width: vw(111px)
    height: vw(794px)
    position: absolute
    background-image: url(../assets/img/ball/top.svg)
    background-size: contain
    background-repeat: no-repeat
    z-index: 20
    top: vw(-612px)
    left: vw(140px)
  &__round
    width: vw(390px)
    height: vw(390px)
    top: vw(161px)
    position: absolute
    &-shadow
      position: absolute
      width: 100%
      height: 100%
      border-radius: 50%
      background: #FFFFFF
      opacity: 0.6
      filter: blur(50px)
      border: 10px solid #FFFFFF
      animation: shadow_zoom
      animation-duration: 5s
      animation-timing-function: linear
      animation-iteration-count: infinite
    &-light
      position: absolute
      width: vw(364px)
      height: vw(295px)
      background-image: url(../assets/img/ball/glass.svg)
      background-size: contain
      background-repeat: no-repeat
      right: 0
      top: 0
      z-index: 10
    &-snow
      position: absolute
      width: vw(318px)
      height: vw(116px)
      background-image: url(../assets/img/ball/snow.svg)
      background-size: contain
      background-repeat: no-repeat
      right: vw(17px)
      bottom: 0
      z-index: 9
    &-bg
      position: absolute
      width: 100%
      height: 100%
      background-color: #183568
      border-radius: 50%
      opacity: 0
      transition: all 300ms
      border: vw(2px) solid #fff
    &-snowbg
      position: absolute
      width: 100%
      height: 100%
      background-color: #183568
      border-radius: 50%
      background-image: url(../assets/img/ball/snow-bg.svg)
      background-size: contain
      background-repeat: no-repeat
    &-glass
      position: absolute
      width: 100%
      height: 100%
      background-image: url(../assets/img/ball/main.svg)
      background-size: contain
      background-repeat: no-repeat
.scene-wrapper
  position: relative
  z-index: 100
  overflow: hidden
  width: 100vw
  height: 100vh
.scene-box
  position: relative
  z-index: 100
  overflow: hidden
  width: 100vw
  height: 100vh
.snowflake
  position: absolute
  width: 10px
  height: 10px
  background: linear-gradient(white, white)
  border-radius: 50%
  filter: drop-shadow(0 0 10px white)
  @media screen and (max-width: 1000px)
    filter: none !important
#snow2
  z-index: 8
  animation: ball_snow
  animation-duration: 5s
  animation-timing-function: linear
  animation-iteration-count: infinite
  .snowflake
    background: none !important
    filter: none !important
    &:before
      content: '*'
      color: #fff
      font-family: 'Georgia'
      display: block
      font-size: vw(50px)
.logo
  display: block
  width: vw(100px)
  height: vw(38.4px)
  background-image: url('../assets/img/logo.svg')
  background-size: contain
  background-repeat: no-repeat
  transition: all 300ms
  z-index: 9999
  position: relative
  &.active
    transform: translateX(vw(528px)) translateY(vw(-30px)) scale(0.92)
.main
  &__share
    position: fixed
    top: vw(18px)
    right: vw(19px)
    &:hover
      .main__share-block
        opacity: 1
  &-block
    position: absolute
    opacity: 0
    width: 14.83vw
    left: -14.83vw
    margin-top: -3.7vw
    .content__share-label
      padding-bottom: 0.4vw
    .content__share-item--vk
      width: 1.5vw
      margin-right: 1.2vw
    .content__share-item--ok
      width: 0.8vw
      margin-right: 10
  &-heart
    width: vw(22px)
    height: vw(20px)
    svg
      width: 100%
      height: 100%
      cursor: pointer
  &__left
    padding-left: vw(139px)
    padding-top: vw(50px)
  &__button
    a
      display: inline-block
      font-size: vw(14px)
      line-height: vw(38px)
      padding-left: vw(40px)
      padding-right: vw(40px)
      background-color: rgba(255, 255, 255, 1)
      border-radius: vw(50px)
      margin-top: vw(27px)
      color: #183568
      font-weight: 600
      overflow: hidden
      transition: all 300ms
      position: relative
      letter-spacing: vw(-0.2px)
      &:before
        content: ''
        display: block
        transition: all 300ms
        width: 100%
        height: 100%
        position: absolute
        left: 0
        opacity: 0
        top: 0
        background-color: #183568
      &:hover
        &:before
          opacity: 0.3
  &__text
    width: vw(420px)
    font-size: vw(18px)
    font-weight: 500
    margin-top: vw(22px)
    line-height: vw(26.1px)
    @media (min-width: 1000px)
      font-weight: 600

    p
      margin-bottom: vw(20px)
    strong
      font-weight: 700
      letter-spacing: vw(-0.2px)
  &__title
    font-size: vw(40px)
    line-height: vw(50px)
    font-weight: 600
    margin-top: vw(38px)
    letter-spacing: vw(-0.5px)
    span
      font-size: vw(140px)
      line-height: vw(83px)
@keyframes ball
  0%
    transform: rotate(0deg)
  40%
    transform: rotate(2deg)
  80%
    transform: rotate(-2deg)
  100%
    transform: rotate(0deg)
@keyframes ball_snow
  0%
    transform: rotate(0deg)
  40%
    transform: rotate(-2deg)
  80%
    transform: rotate(2deg)
  100%
    transform: rotate(0deg)
@keyframes shadow_zoom
  0%
    transform: scale(1)
  50%
    transform: scale(1.2)
  100%
    transform: scale(1)
@media screen and (max-width: 1000px)
  .scene-box
    height: auto
    min-height: 100vh
  .ball__round
    width: vwm(208px)
    height: vwm(208px)
    &-snow
      width: vwm(169px)
      height: vwm(62px)
      right: vwm(10px)
  .ball__top
    width: vwm(60px)
    height: vwm(423px)
    top: vwm(-382px)
    left: vwm(74px)
  .ball-wrapper
    left: vwm(55px)
    top: vwm(44px)
    &.active
      transform: none
      left: vwm(-74px)
      .ball__round-snow
        display: none
      #snow
        display: none
      .ball__top
        width: vwm(133px)
        height: vwm(947px)
        top: vwm(-890px)
        left: vwm(164px)
      .ball__round
        width: vwm(466px)
        height: vwm(466px)
  .main__left
    padding-top: vwm(327px)
    padding-left: vwm(21px)
    padding-right: vwm(21px)
    padding-bottom: vwm(50px)
  .logo
    display: block
    width: vwm(78px)
    height: vwm(40px)
    &.active
      transform: translateX(30.666667vw) translateY(-86vw) scale(0.92)
      background-image: url(../assets/img/logo-invert.svg)
  .ball__round-bg
    background-color: rgba(255,255,255,0.2)
  .scene-box
    overflow: visible
    overflow-x: hidden
  .main__title
    font-size: vwm(30px)
    line-height: vwm(50px)
    letter-spacing: vwm(-0.5px)
    margin-top: vwm(13px)
    span
      font-size: vwm(100px)
      line-height: vwm(85px)
  .active
    .main
      padding-bottom: vwm(170px)
  .main__button
    a
      margin-top: vwm(21px)
      font-size: vwm(12px)
      height: vwm(38px)
      line-height: vwm(38px)
      padding: 0px vwm(40px)
      border-radius: vwm(50px)
  .content
    width: vwm(280px)
    height: auto
    padding-bottom: vwm(30px)
    margin-top: vwm(-257px)
    position: relative
    border-radius: vwm(10px)
    &__box
      width: vwm(260px)
      height: auto
    &__info
      height: auto
      padding-bottom: vwm(20px)
      border-bottom-left-radius: vwm(10px)
      border-bottom-right-radius: vwm(10px)
      p
        font-size: vwm(12px)
        line-height: vwm(20px)
        padding: 0 vwm(5px)
        margin-top: vwm(10px)
    &__logo
      width: vwm(160px)
      height: vwm(40px)
      margin-top: vwm(30px)
      &--row
        display: flex
        justify-content: center
        width: 100%
        flex-direction: column
        align-items: center
        img
          width: vwm(120px) !important
          height: auto !important
          margin-right: vw(0px)
          margin-bottom: vwm(10px)
          &:last-child
            margin-right: 0
    &__head
      height: auto
      font-size: vwm(18px)
      line-height: 1.2
      background-size: 200vw
      border-top-left-radius: vwm(10px)
      border-top-right-radius: vwm(10px)
      padding: vwm(24px) vwm(12px)
      p
        margin-bottom: vwm(21px)
  .content__button
    margin-top: vwm(56px)
    a
      height: vwm(35px)
      display: block
      width: 76%
      margin: auto
      line-height: vwm(35px)
      border-radius: vwm(50px)
      position: relative
      left: vwm(7px)
      font-size: vwm(12px)
      padding-left: vwm(20px)
      img
        width: vwm(14px)
        height: vwm(15px)
        margin-left: vwm(-14px)
        position: relative
        top: vwm(4px)
        margin-right: vwm(7px)
  .content__share-item
    display: flex
    align-items: center
  .content__share-list
    align-items: center
  .content__share-item--vk
    margin-right: vwm(20px)
    width: vwm(32px)
    height: vwm(30px)
  .content__share-item--ok
    transform: translate(vwm(1.2px), vwm(0.6px))
    width: vwm(22px)
    height: vwm(20px)
  .content__share
    flex-direction: column
    padding-top: vwm(20px)
    &-list
      margin-top: vwm(35px)
      margin-bottom: vwm(-10px)
    &-label
      text-align: center
      font-size: vwm(12px)
      padding-top: vwm(7px)
      padding-left: vwm(13px)
  .main__text
    width: 100%
    font-size: vwm(14px)
    line-height: vwm(20px)
    p
      &:nth-child(1)
        width: 77%
        margin-bottom: vwm(10px)
      &:nth-child(2)
        margin-bottom: vwm(10px)
      &:nth-child(3)
        width: 77%
        margin-bottom: vwm(10px)
  .notice
    position: relative
    top: 0
    margin-top: vwm(20px)
  .notice__text
    font-size: vwm(20px)
    letter-spacing: vwm(7px)
    line-height: 1.2
    color: #fff
    padding-top: vwm(4px)
    padding-left: vwm(7px)
    &.mini
      font-size: 5.308333vw
      padding-top: 3vw
    &.micro
      font-size: 4.308333vw
      padding: 2vw
  .content__head p.small
    font-size: vwm(14px)
    line-height: vwm(20px)
    width: 100%
    margin-left: 0
  .main__share
    display: none
  .active .main
    height: auto
    padding-bottom: 0
  .content__info p span
    font-size: 2.5vw
    line-height: 1
    transform: translateY(-1.3vw)
  .content__head p span
    font-size: 2.5vw
    line-height: 1
    transform: translateY(-1.3vw)
  .content__info p
    br
      display: none
  .content__small
    font-size: 3.4vw
    text-align: center
    padding-top: 6vw
    padding-left: 5vw
    padding-right: 5vw
  .content__share-item a
    display: block
    overflow: visible

.content--3 .content__logo
  @media (min-width: 1000px)
      height: vw(35px)
      margin-top: vw(10px)

.wobble-hor-top
  @media (max-width: 1000px)
    top: vwm(200px)
</style>

<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.up-enter-active {
  animation: bounce-in 0.5s;
}
.up-leave-active {
  animation: bounce-up 0.5s reverse;
}
.fade-text-enter-active {
  animation: fade-text-in 0.5s;
}
.fade-text-leave-active {
  animation: fade-text-in 0.5s reverse;
}
@keyframes fade-text-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-up {
  0% {
    bottom: -50vw;
  }
  100% {
    bottom: 0;
  }
}
.wobble-hor-top {
  animation: wobble-hor-top 1s both !important;
  -webkit-animation-iteration-count: infinite !important;
  -moz-animation-iteration-count: infinite !important;
  -o-animation-iteration-count: infinite !important;
  animation-iteration-count: infinite !important;
}

@media screen and (max-width: 1000px) {
  .wobble-hor-top {
    animation: wobble-hor-top-mob 1s both !important;
    -webkit-animation-iteration-count: infinite !important;
    -moz-animation-iteration-count: infinite !important;
    -o-animation-iteration-count: infinite !important;
    animation-iteration-count: infinite !important;
  }
}
@keyframes wobble-hor-top {
  0%,
  100% {
    transform: translateX(-26.49vw) translateY(-3.73vw) scale(1.3);
  }
  15% {
    transform: translateX(-25.49vw) translateY(-3.73vw) rotate(6deg) scale(1.3);
  }
  30% {
    transform: translateX(-26.49vw) translateY(-3.73vw) rotate(-6deg) scale(1.3);
  }
  45% {
    transform: translateX(-25.49vw) translateY(-3.73vw) rotate(3.6deg)
      scale(1.3);
  }
  60% {
    transform: translateX(-26.49vw) translateY(-3.73vw) rotate(-2.4deg)
      scale(1.3);
  }
  75% {
    transform: translateX(-25.49vw) translateY(-3.73vw) rotate(1.2deg)
      scale(1.3);
  }
}
@keyframes wobble-hor-top-mob {
  0%,
  100% {
    transform: translateX(-1.49vw) translateY(-1.43vw);
  }
  15% {
    transform: translateX(1.49vw) translateY(1.43vw) rotate(6deg);
  }
  30% {
    transform: translateX(1.49vw) translateY(1.43vw) rotate(-6deg);
  }
  45% {
    transform: translateX(-1.49vw) translateY(-1.43vw) rotate(3.6deg);
  }
  60% {
    transform: translateX(1.49vw) translateY(1.43vw) rotate(-2.4deg);
  }
  75% {
    transform: translateX(-1.49vw) translateY(-1.43vw) rotate(1.2deg);
  }
}
body {
  -webkit-perspective: 0px !important;
  -webkit-backface-visibility: visible !important;
  -webkit-transform: translate3d(0, 0, 0);
}

.custom-link {
  color: #183568;
  font-weight: 700;
  text-decoration: underline;
}
</style>
