import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Главная',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes
})

export default router
